// @import "bootstrap/_custom.scss";
$slick-font-path: "~slick-carousel/slick/fonts/";
$slick-loader-path: "~slick-carousel/slick/";
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

.slick-arrow {
    z-index: 1000;
    height: 50px;
    width: 50px;

    &.slick-next {
        right: 25px;
    }
    &.slick-prev {
        left: 25px;
    }
    &.slick-prev::before, &.slick-next::before {
        color: #FCD631;
        font-size: 44px;
    }
}
// .slick-list{
//     padding:0 20% !important;
// }
.slick-slider .image img {
    max-height: 600px;
}